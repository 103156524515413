.profile {
  position: absolute;
  z-index: 9;
  width: 70vh;
  height: 46vh;
  display: flex;
  align-content: flex-end;
  overflow: hidden;
}
.List {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 20vh;
}
.role {
  padding-bottom: 5px;
  font-weight: 500;
  line-height: 1.5;
  padding-left: 1.3rem;
}
.email {
  padding-left: 1.3rem;
}
.role.css-ahj2mt-MuiTypography-root {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 1.2rem;
  color: rgb(17, 25, 39);
}
.email.css-1exmgyc-MuiTypography-root {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 500;
  color: rgb(108, 115, 127);
  font-size: 0.875rem;
}
.txts.css-ahj2mt-MuiTypography-root {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
.out {
  display: flex;
  align-items: center;
  justify-content: center;
}
.log.css-ahj2mt-MuiTypography-root {
  font-weight: 600;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 1rem;
  line-height: 1.75;
  cursor: pointer;
  margin-top: 10px;
  padding: 7px 16px;
}
.log.css-ahj2mt-MuiTypography-root:hover {
  background-color: #f1f3f4;
  border-radius: 7rem;
  text-align: center;
  padding: 7px 16px;
}
.set:hover {
  background-color: #f1f3f4;
  cursor: pointer;
  border-radius: 3px;
}
