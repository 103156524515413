.head-title.css-ahj2mt-MuiTypography-root{
    font-size: 2rem;
    font-weight: bold;
    
}
.main-content{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.main-content2{

    display: flex;
    flex-direction: column;
    align-items: first baseline;
    margin-top: 12px;
}
.head-content.css-ahj2mt-MuiTypography-root{
    margin-left: 1rem;
    font-family: var(--font-face, "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif);
    font-size: 13px;
    margin-top: 18px;
    padding-right: 30px;
    font-weight: bold;
}
.head-content1.css-5lbw0b-MuiTypography-root {
    margin-left: 0.1rem;
    padding-right: 30px;
    margin-top: 2px;
    font-size: 2.5rem;
    font-weight: bold;
 
}
.dashboard-icon.css-i4bv87-MuiSvgIcon-root { 
    font-size: 60px; 
    color: #333; }
    .main-content:hover {
        transition: transform 0.3s ease-in-out;
        transform: translateY(-5px); /* Adjust the value for desired movement */
      }
      
      .card-container {
        transition: box-shadow 0.3s ease-in-out;
        cursor: pointer;
      }
      
      .card-container:hover {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
      }