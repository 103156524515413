.del-cancelbtn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: white;
  color: black;
  font-size: 12px;
  padding: 9px 25px;
  font-weight: bold;
  border-radius: 12px;
  border: 1px solid;
}
.del-deletebtn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(99, 102, 241);
  color: white;
  font-size: 12px;
  padding: 10px 25px;
  font-weight: bold;
  border-radius: 12px;
}
.del-deletebtn.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgb(37, 41, 243);
  color: white;
  font-size: 12px;
  font-weight: bold;
}
