.typo.css-5lbw0b-MuiTypography-root {
  padding: 2rem 2rem;
  font-size: 2rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-left: 6.8rem;
}
.btn-up.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(99 102 241);
  margin-left: 2rem;
  font-weight: 600;
  padding: 9px 27px;
  border-radius: 12px;
}
.btn-up.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgb(71 74 231);
  font-weight: 600;
}
.btn1.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: aliceblue;
  color: rgb(71 74 231);
  font-weight: 600;
  padding: 9px 16px;
  border-radius: 12px;
}
.btn1.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: aliceblue;
  font-weight: 600;
}
.datepicker.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.buttons {
  display: flex;
  width: 85.7%;
  justify-content: flex-end;
  padding: 2rem 2rem;
}
.error-border {
  border-color: red !important;
}
.load{
  width: 15px !important;
  height: 15px !important;
}
/* Assuming 'customDatePicker' is the class you added to the DatePicker */
.customDatePicker .MuiInputBase-root {
  height: 40px;
  width: 437px; 
  margin-bottom: 10px;
}
