.add-bt.css-sghohy-MuiButtonBase-root-MuiButton-root{
    background-color: rgb(88, 91, 243);
    text-transform: capitalize;
    padding: .3rem 1.7rem;
}.add-bt.css-sghohy-MuiButtonBase-root-MuiButton-root:hover{
    background-color: rgb(20, 23, 196);
}
.cnl-bt.css-sghohy-MuiButtonBase-root-MuiButton-root{
background-color: #ee6666;
text-transform: capitalize;
padding: .3rem 1.7rem;
}
.cnl-bt.css-sghohy-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #ee1818;
   
}
.cnl-bt.css-19va6yi-MuiButtonBase-root-MuiButton-root{
    background-color: rgb(88, 91, 243);
    color: white;
    font-weight: bold;
}
.cnl-bt.css-19va6yi-MuiButtonBase-root-MuiButton-root:hover{
    background-color: rgb(20, 23, 196);
}
.cnl-bt.css-1mo2k7y-MuiButtonBase-root-MuiButton-root{
    background-color: #ee6666;
    color: white;
    font-weight: bold;
}
.cnl-bt.css-1mo2k7y-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #ee1818;
}