

.log.css-cveggr-MuiListItemIcon-root {
  min-width: 50px;
}

::-webkit-scrollbar {
    width: 8px;  
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 4px; 
   
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
  }  

