.btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(99, 102, 241);
  color: white;
  font-size: 12px;
  padding: 8px 20px;
  font-weight: bold;
  border-radius: 12px;
}
.btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgb(37, 41, 243);
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.dialog.css-hz1bth-MuiDialog-container {
  width: 50%;
  height: 100%;
}
.crumbs.css-17sj1r4-MuiTypography-root-MuiLink-root {
  font-size: 12px;
  color: rgb(17, 25, 39);
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.crumbs.css-ahj2mt-MuiTypography-root {
  font-size: 12px;
  color: rgb(108, 115, 127);
  margin-top: 1px;
}
.links {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 3rem;
}
.separator {
  background-color: rgb(108, 115, 127);
  border-radius: 50%;
  height: 4px;
  width: 4px;
  padding: 0%;
  margin: 0;
}
.btn-upd.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(99 102 241);
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 12px;
}
.btn-upd.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgb(71 74 231);
  font-weight: 600;
}
.btn2.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: aliceblue;
  color: rgb(71 74 231);
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 12px;
}
.btn2.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: aliceblue;
  font-weight: 600;
}
.del.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(99 102 241);
  font-weight: 600;
  padding: 7px 18px;
}
.del.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgb(71 74 231);
  font-weight: 600;
}
.cancel.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: aliceblue;
  color: rgb(71 74 231);
  font-weight: 600;
  padding: 7px 18px;
}
.cancel.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: aliceblue;
  font-weight: 600;
}
.search {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
  width: 100%;
  border-radius: 10px;
}
