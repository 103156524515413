.Toastify__toast-container {
  position: fixed;
  top: 4.5rem;
  right: 4rem;
  z-index: 9999;
}

.Toastify__toast {
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 12px 15px;
  font-size: 17px;
  width: max-content;
}

.Toastify__progress-bar {
  height: 7px;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background: linear-gradient(to right, #9b63f5, #f78437, #48ecdf);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: linear-gradient(to right, #9b63f5, #f78437, #48ecdf);
}
