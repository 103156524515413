.signinp {
  color: orangered;
  margin-left: 0%;
  background-color:rgb(80, 76, 76)
}
.error.css-ahj2mt-MuiTypography-root {
  color: red;
  font-size: 12px;
  margin-bottom: 0.7rem;
  margin-top: 0.5rem;
}
.loader{
  width: 25px !important;
  height: 25px !important;
}
